import React, { useState, useEffect } from "react";
import WerewolvesSound from "../sound/werewolves.mp3";
import "./WerewolvesButton.css";

const WerewolvesButton = ({
  night,
  werewolvesRound,
  onChangeRound,
  NightAudio,
}) => {
  //const WerewolvesAudio = new Audio(WerewolvesSound);
  const [audio, setAudio] = useState( new Audio(WerewolvesSound) )
  audio.volume = 0.5;
  const handlePlay = () => {
    if (night) {
      if (!werewolvesRound) {
        NightAudio.pause();
        NightAudio.currentTime = 0 // Rewind NightAudio to the beginning
        audio.play();
        console.log("werewolves");
        fetch(
          `https://home-assistant.lerepaireduphoenix.fr/api/webhook/${process.env.REACT_APP_URL_HOME_ASSISTANT}-werewolvesround`,
          {
            method: "POST",
            redirect: "follow",
          }
        )
          .then((response) => response.text())
          .then((result) => console.log(result))
          .catch((error) => console.log("error", error));
      } else {
        console.log("end Werewolves");

        audio.pause();
        console.log(audio.paused);
        audio.currentTime = 0;
        
        NightAudio.play();
        fetch(
          `https://home-assistant.lerepaireduphoenix.fr/api/webhook/${process.env.REACT_APP_URL_HOME_ASSISTANT}-werewolvesnight`,
          {
            method: "POST",
            redirect: "follow",
          }
        )
          .then((response) => response.text())
          .then((result) => console.log(result))
          .catch((error) => console.log("error", error));
      }
    }
  };

  const handlecheckbox = () => {
    onChangeRound();
    handlePlay();
  };

  return (
    <div
      id="werewolvesButton"
      style={{
        backgroundColor: "white",
        fontSize: "3em",
        marginTop: "20px",
      }}
    >
      <input
        type="checkbox"
        id="werewolves"
        onChange={handlecheckbox}
        style={{ width: "30px", height: "30px" }}
      />
      <label htmlFor="werewolves">Werewolves Round</label>
    </div>
  );
};

export default WerewolvesButton;
