import React, { useState } from "react";
import { useEffect } from "react";
import RoleTile from "./RoleTile";

const GameCreation = ({ Partie, roles, NbPlayer, onCheckboxChange, onInputNumberChange, onChangeNumberPlayer }) => {

  const [rolesAJoue, setRolesAJoue] = useState([]);
  const [NombreJoueur, setNbJoueur] = useState(0);

  function CheckNbplayer( nbdiff ) {
    console.log(nbdiff);

    return null;
  }

  function AlerteNbplayer( visible ) {

    return null;
  }

  function onCheckboxChange(checked,itemId) {
    var updatedRolesAJoue = [];
    if(checked){
       updatedRolesAJoue = [...rolesAJoue, itemId];
    }else{
       updatedRolesAJoue = rolesAJoue.filter(x => x !== itemId);
    }
    // Mettre à jour l'état avec la nouvelle liste
    setRolesAJoue(updatedRolesAJoue);
  }

  function onChangeNumberPlayer(valueplayer){
    setNbJoueur(valueplayer);
  }

  function launchGame() {
    fetch(`${process.env.REACT_APP_WEREWOLVES_API_URL}/Partie/AddRolesToGame`, {
      method: "Post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(rolesAJoue)
    })
      .then((res) =>{
        console.log(res.json())
      
        fetch(`${process.env.REACT_APP_WEREWOLVES_API_URL}/Partie/NextStep`, {
          method: "Post",
          headers: {
            "Content-Type": "application/json",
          }
        })
          .then((res) => {
            
            console.log(res.json());
            // nextcyle
            window.location.reload() //refresh page
          })
          .catch((err) => {
            console.log(err.message);
          })})
      .catch((err) => {
        console.log(err.message);

      }); //fetch etape Jeu
    // fetch role post


  }

  function roletile(role)
  {
        return  (<RoleTile id={role.order} name={role.name} imageName={role.imageName === null ? `default` : role.imageName} select={role.order == 118 } onCheckboxChange={onCheckboxChange}/>)
  } 

  function listRole(roles,type){
    return (
      <div className="wrapper">
        {roles.filter((role) => { return role.type == type })
          .map((role) => (
            roletile(role)
          ))}
      </div>
    )
  }

  useEffect(()=>{
    var roletoplay = roles.filter(x => rolesAJoue.some(y => y == x.order)).reduce((a,v)=> a = a + v.nb,0);
    CheckNbplayer(NombreJoueur - roletoplay);
  },[rolesAJoue,NombreJoueur])

  return (
    <div className="gameCreation">
      <AlerteNbplayer />
      <div>
        Nombre de joueurs : <input type="number" name="nbPlayer" id="nbplayer" value={NombreJoueur} onChange={(e) => onChangeNumberPlayer(e.target.value)} />
      </div>
      <h3>Villageois</h3>
      {listRole(roles, 1)}
      <br />
      <h3>Loups-Garous</h3>

      {listRole(roles, 2)}
      <br />
      <h3>Indépendants</h3>

      {listRole(roles, 3)}
      <br />
      <h3>Villageois Spéciaux</h3>

      {listRole(roles, 4)}
      <div>
        <button onClick={launchGame} >Commencer la Partie</button>
      </div>
    </div>
  )
}

export default GameCreation;