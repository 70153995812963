import React from "react";
import backgroundDay from "../img/background/background_day.png";
import backgroundNight from "../img/background/background_night.png";

import "../Component/DayNightCycle.css";
import nigthSound from "../sound/night.mp3";
import daySound from "../sound/day.mp3";

export class DayNightCycle extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isChecked: false };
    this.props.NightAudio.loop = true;
    this.props.NightAudio.volume = 0.6;

    this.DayAudio = new Audio(daySound);
    this.DayAudio.volume = 0.6;
    this.DayAudio.loop = true;

    // set this (with .bind),
    // because you need get methods from CheckBox, like .setState
    this.handleChecked = this.handleChecked.bind(this);
  }

  handleChecked() {
    this.setState({ isChecked: !this.state.isChecked });
    this.props.night(!this.state.isChecked);

    if (!this.state.isChecked) {
      console.log("night");
      this.DayAudio.pause();
      this.props.NightAudio.currentTime = 0;
      this.props.NightAudio.play();
      this.setBackgroundImage(`url(${backgroundNight})`);
      fetch(
        `https://home-assistant.lerepaireduphoenix.fr/api/webhook/${process.env.REACT_APP_URL_HOME_ASSISTANT}-werewolvesnight`,
        {
          method: "POST",
          redirect: "follow",
        }
      )
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    } else {
      console.log("day");
      this.props.NightAudio.pause();
      this.DayAudio.currentTime = 0;
      this.DayAudio.play();
      this.setBackgroundImage(`url(${backgroundDay})`);
      fetch(
        `https://home-assistant.lerepaireduphoenix.fr/api/webhook/${process.env.REACT_APP_URL_HOME_ASSISTANT}-werewolvesday`,
        {
          method: "POST",
          redirect: "follow",
        }
      )
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    }
  }

  setBackgroundImage(urlimage) {
    document.documentElement.style.setProperty(
      "--bodyBackgroundImage",
      urlimage
    );
  }

  render() {
    return (
      <>
        <div id="dayNightCycle" style={{ margin: "8px" }}>
          <label className="switch">
            <input
              type="checkbox"
              id="toggle-input"
              onChange={this.handleChecked}
            />
            <div id="toggle-button">
              <div id="toggle-cursor"></div>
            </div>
          </label>
        </div>
      </>
    );
  }
}

//ReactDOM.render(<DayNightCycle />, document.getElementById("hello"));
